import {
    Button, createStyles, Divider, IconButton, List, ListItem,
    ListItemText, makeStyles, MobileStepper, Theme, Typography
} from '@material-ui/core';
import React, {useContext, useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';
import PermissionContext, {Process} from '../Permissions/Context';
import BottomNav from "../CustomBottomNav";
import TermsCard from '../Settings/T&C';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import EditForm from '../EditForm';
import { IField } from './Data';
import HelpIcon from '@material-ui/icons/Help';
import ConfirmDialog from '../Notifications/ConfirmDialog';
import FileUploadForm from "../FileUploadForm";

interface IRequest {
    id: string | undefined
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '85%',
            maxWidth: '36ch',
            backgroundColor: theme.palette.background.paper,
            margin: "20px auto",
            height: "auto",
        },
        inline: {
            display: 'inline',
        },
        button: {
            textTransform: 'none',
            fontWeight: 'bold'
        },
        item: {
            paddingTop: 0,
            paddingBottom: 0,
            margin: 0
        },
        dot: {
            backgroundColor: "grey"
        },
        dotActive: {
            backgroundColor: "white"
        }
    }),
);

const DataProcessorRequest = (data: IRequest) => {
    const history = useHistory()
    // const { id } = useParams<{ id: string }>();
    const { rqResource, grantPermission, grantPermissionForBulkData, denyPermission } = useContext(PermissionContext)
    const [hash, setHash] = useState()
    const [activeStep, setStep] = useState(0);
    const [edit, setEdit] = useState(false);
    const [fieldInfo, setFieldInfo] = useState<[IField]>()
    const [editData, setEditData] = useState()
    const [files, setFiles] = useState<File[]>()
    const [dialogOpen, setDialogOpen] = useState(false);

    const classes = useStyles();

    useEffect(() => {
        const data1: any = rqResource;
        const data2: [IField] = data1?.content.fieldset;
        let obj: any = {};
        data2.forEach((entry: IField) => obj[entry.uuid] = entry.value)
        setFieldInfo(data2);
        setEditData(obj)
    }, [rqResource]);

    // show all fields in the request
    const dataEntry = (entries: any) => {
        return (
            <List className={classes.root}>
                {Object.values(entries).map((element: any, index) =>
                    <>
                        {
                            <>
                                <ListItem key={element.uuid} alignItems="flex-start" className={classes.item}>
                                    <ListItemText
                                        primary={
                                            <React.Fragment>
                                                <Typography
                                                    style={{ lineHeight: 0, fontSize: "15px" }}
                                                    component="span"
                                                    variant="body2"
                                                    className={classes.inline}
                                                    color="textSecondary"
                                                >
                                                    {element.name}
                                                </Typography>
                                            </React.Fragment>
                                        }
                                        secondary={
                                            <React.Fragment>
                                                <Typography
                                                    style={{ fontSize: "17px", lineHeight: 0 }}
                                                    component="span"
                                                    variant="body1"
                                                    className={classes.inline}
                                                    color="textPrimary"
                                                >
                                                    {element.value ? element.value : "No data"}
                                                </Typography>
                                            </React.Fragment>
                                        }
                                    />
                                </ListItem>
                                {index < Object.values(entries).length - 1 && <Divider component="li" />}
                            </>
                        }

                    </>
                )}
            </List>
        )
    }

    let title: string = " ";
    let URI: string = " ";
    let policyLink = "";
    let process: Process

    if (rqResource) {
        const name: any = rqResource.content;
        title = name.data_processor.commonName
        URI = name.data_processor.URI
        policyLink = name.legal_policy;
        process = Process[name.process as keyof typeof Process]
    } else {
        return <><h3>loading</h3></>
    }

    const increaseStep = () => {
        if (activeStep === 1) {
            setDialogOpen(true);
        } else {
            setStep(activeStep + 1);
        }
    }

    const decreaseStep = () => {
        setStep(activeStep - 1);
    }

    const submitStep = () => {
        if (activeStep === 1) {
            return "Allow Access"
        }
        return "Continue"
    }

    const open = () => {
        setEdit(true)
    }

    const onSubmit = (data: any, submittedData: any) => {
        const parseData: [IField] = data;
        setFieldInfo(parseData)
        setEditData(submittedData)
        setEdit(false)
    }

    const onFileSelect = (files: File[]) => {
        setFiles(files)
    }

    const denied = () => {
        denyPermission(data.id)
        history.push("/dashboard")
    }


    const closeDialog = () => {
        setDialogOpen(false);
    }

    const confirm = () => {
        if (process === Process.INDIVIDUAL) {
            grantPermission(data.id, editData)
        } else if (process === Process.BULK) {
            grantPermissionForBulkData(data.id, files)
        }
        history.push("/dashboard")
    }

    return (
        <div className="ProcessorContainer">
            <ConfirmDialog open={dialogOpen} closeDialog={closeDialog} handleConfirm={confirm} dialogType="accept" />
            {!edit ?
                <>
                    <div className="Search" style={{ justifyContent: "space-between" }}>
                        <div style={{ height: "100%" }}>
                            <Button onClick={() => history.push("/dashboard")} style={{ color: "white", marginTop: "10px", marginLeft: "5px" }} className={classes.button}>
                                Close
                            </Button>
                        </div>
                        <div className="tl" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <img src={URI} width="25" height="25" style={{ background: "white", margin: "5" }} />
                            <text style={{ color: "white", margin: 0 }}>{title}</text>
                            <text style={{ color: "white", margin: 0, fontSize: 12 }}>Review your personal data</text>
                            <MobileStepper
                                style={{ background: "inherit" }}
                                variant="dots" position="static"
                                steps={2}
                                activeStep={activeStep}
                                nextButton={null}
                                backButton={null}
                                classes={{ dot: classes.dot, dotActive: classes.dotActive }}
                            />
                        </div>
                        <div style={{ width: "64px", height: "36px" }}></div>
                    </div>
                    <div className="ListContent">
                        {activeStep === 0 && fieldInfo ?
                            <>
                                <div style={{ marginTop: "15px", width: "80%", alignSelf: "center", fontSize: 14, fontWeight: 400 }}>
                                    <text>Share sensitive data securely from a dataU organization</text> <HelpIcon style={{ color: "#004C60", width: 15, height: 15 }} />
                                </div>
                                {process === Process.INDIVIDUAL && dataEntry(fieldInfo)}
                                {process === Process.BULK && <FileUploadForm fileSelectHandler={onFileSelect}/>}
                            </>
                            :
                            <>
                                <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center" }}>
                                    <IconButton style={{cursor: 'crosshair'}} onClick={decreaseStep}><ArrowBackIcon /></IconButton>
                                    <Typography style={{ color: "textPrimary", fontSize: 14, fontWeight: "bold" }}>
                                        Review terms and conditions
                                    </Typography>
                                    <div style={{ width: "48px", height: "48px" }}></div>
                                </div>
                                <TermsCard url={policyLink} />
                            </>
                        }
                    </div>
                    {/*<input onChange={(e: any) => setHash(e.target.value)}></input> <button onClick={() => grantPermission(hash)}>Send</button>*/}
                    <BottomNav goNext={increaseStep} goBack={decreaseStep} finishString={submitStep} edit={open} cancel={denied} />
                </> :
                <>
                    <div className="FlatHeader" style={{ justifyContent: "space-between" }}>
                        <Button onClick={() => setEdit(false)} style={{ color: "white" }} startIcon={<ArrowBackIcon />}>
                            Back
                        </Button>
                        <div className="tl" style={{ color: "white", fontWeight: "bold" }}>
                            Edit
                    </div>
                        <Button form="my-form" type="submit" style={{ color: "white" }}>Save</Button>
                    </div>
                    <div className="Content">
                        <EditForm data={fieldInfo} submitHandler={onSubmit} name={title} img={URI} />
                    </div>
                </>
            }
        </div >
    )
}
export default DataProcessorRequest;
