import React, { useReducer } from "react"
import { useHistory } from "react-router-dom";
import useNotification from "../Notifications/NotificationHook";
import PermissionContext, { ActionType, initialState } from "./Context"

import permissionReducer from "./Reducer"

const PermissionProvider = (props: React.PropsWithChildren<{}>) => {
    const [state, dispatch] = useReducer(permissionReducer, initialState);

    const { addNotification } = useNotification()        

    const history = useHistory();

    async function getData(url = '') {
        const response = await fetch(url, {
            method: 'GET',
            mode: 'same-origin',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
        }).then(response => {
            if (!response.ok) {
                if (response.status === 401) {
                    addNotification('You are unauthorized, please login', response.status)
                    history.push('/authenticate')
                } else {
                    addNotification('Something went wrong', response.status)
                }
            }
            return response.json()
        })
        return response
    }

    async function postData(url = '', data = {}) {
        // Default options are marked with *
        const response = await fetch(url, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(data) // body data type must match "Content-Type" header
        }).then(response => {
            if (!response.ok) {
                if (response.status === 401) {
                    addNotification('You are unauthorized, please login', response.status)
                    history.push('/authenticate')
                } else {
                    addNotification('Something went wrong', response.status)
                    history.push('/dashboard')
                }
            }
            return response.json()
        })
        return response
    }

    const getDataProcessors = async () => {
        const url = '/api/data-processors';
        dispatch({ kind: ActionType.SET_LOADING, loading: true })
        getData(url)
            .then(response => {
                const data = response;
                dispatch({ kind: ActionType.SET_RESOURCES, resources: data });
                dispatch({ kind: ActionType.SET_LOADING, loading: false });
                dispatch({ kind: ActionType.SET_REQUEST_RESOURCE, rqResource: undefined })
                
            })
            .catch(error => {console.log(error)
                dispatch({ kind: ActionType.SET_RESOURCES, resources: undefined });
            })
            .finally(() => dispatch({ kind: ActionType.SET_LOADING, loading: false }))
    }

    const permissionsForDP = async (dataProcessor: string) => {
        if (state.resource){
            return
        }

        const url = '/api/data-processors/' + dataProcessor;
        getData(url)
            .then(response => {
                const data = response;
                dispatch({ kind: ActionType.SET_RESOURCE, resource: data })
            })
    }

    const grantPermission = async (hash: string, data: any) => {
        const url = '/api/permissions/grant';
        const post_data = { 'message': hash, 'data': data }
        postData(url, post_data)
            .then(response => {
                const data = response;
                getDataProcessors()
            });
    }

     const grantPermissionForBulkData = async (hash: string, files: [File]) => {
        const url = '/api/permissions/grant/bulk';
        let formData = new FormData();
        formData.append('message', hash);
        files.forEach(file => {
           formData.append('data', file, file.name);
        });

        await fetch(url, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            headers: {},
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: formData // body data type must match "Content-Type" header
        }).then(response => {
            if (!response.ok) {
                if (response.status === 401) {
                    addNotification('You are unauthorized, please login', response.status)
                    history.push('/authenticate')
                } else {
                    addNotification('Something went wrong', response.status)
                    history.push('/dashboard')
                }
            }
            return response.json()
        });
    }

    function revokePermission(hash: any) {
        const url = '/api/permissions/revoke';
        const post_data = { 'hash': hash }
        postData(url, post_data)
            .then(response => {
                getDataProcessors()
            });
    }

    function denyPermission(hash: any) {
        const url = '/api/permissions/deny';
        const post_data = { 'message': hash }
        postData(url, post_data)
            .then(response => {
            });
    }

    function decodePermission(hash: any) {
        const url = '/api/permissions/decode';
        const post_data = { 'message': hash }
        postData(url, post_data)
            .then(response => {
                const data = response;
                dispatch({ kind: ActionType.SET_REQUEST_RESOURCE, rqResource: data })
            });
    }

    const editPermission = async (hash: string, data: any) => {
        const url = '/api/permissions/edit';
        const post_data = { 'hash': hash, 'data': data };
        postData(url, post_data)
            .then(response => {
                const data = response;
                dispatch({kind: ActionType.SET_RESOURCE, resource: undefined})
            })
    }

    return (
        <PermissionContext.Provider
            value={{
                ...state,
                getDataProcessors,
                permissionsForDP,
                grantPermission,
                grantPermissionForBulkData,
                revokePermission,
                decodePermission,
                editPermission,
                denyPermission,
            }}
        >
            {props.children}
        </PermissionContext.Provider>
    );
};
export default PermissionProvider;