import React, { useState, useCallback } from 'react';

interface IError {
    message?: string,
    status?: number
}

interface Context {
    error: IError,
    addNotification: (message: string, status: number) => any,
    removeNotification: () => any
}

export const NotificationContext = React.createContext<Context>({
    error: { message: undefined, status: undefined },
    addNotification: (message: string, status: number) => { },
    removeNotification: () => { }
});

export default function NotficationProvider({ children }: any) {
    const [error, setError] = useState<IError>({ message: undefined, status: undefined });

    const removeNotification = () => setError({ message: undefined, status: undefined });

    const addNotification = (message: string, status: number) => {
        setError({ message, status });
    }

    return (
        <NotificationContext.Provider value={{ error, addNotification, removeNotification }}>
            {children}
        </NotificationContext.Provider>
    );
}