import { createContext } from "react";

export interface IError {
    message: string;
}

export enum ActionType {
    ERROR,
    SET_RESOURCE,
    SET_LOADING,
    SET_RESOURCES,
    SET_REQUEST_RESOURCE
}

export enum Process {
    INDIVIDUAL,
    BULK
}

export interface IDecode {
    type: string,
    content: {}
}

export interface IAction<T> {
    kind: ActionType;
    rqResource?: IDecode;
    resource?: [];
    resources?: [T];
    errors?: IError[];
    loading?: boolean;
}

export interface IContext {
    errors?: IError[];
    loading?: boolean;
    rqResource?: IDecode;
    resource?: {}
    resources?: {}
    [other: string]: any;
    getDataProcessors(...args: any): void;
    permissionsForDP(...args: any): void;
    grantPermission(...args: any): void;
    grantPermissionForBulkData(...args: any): void;
    decodePermission(...args: any): void;
    editPermission(...args: any): void;
    denyPermission(...args: any): void;
}

export interface Permission {
    hash: string,
    data_processor: string,
    data: string,
    process: string,
    reason: string,
    legal_policy: string,
    access_from: string,
    acces_until: string,
    access_amount: string
}

export type ContextType = IContext;
export const initialState: ContextType = {
    getDataProcessors: () => null,
    grantPermission: (hash: string) => Promise.reject,
    grantPermissionForBulkData: (hash: string) => Promise.reject,
    deletePermission: (hash: string) => Promise.reject,
    permissionsForDP: (hash: string) => Promise.reject,
    decodePermission: (hash: string) => Promise.reject,
    editPermission: (hash: string) => Promise.reject,
    denyPermission: (hash: string) => Promise.reject,
    rqResource: undefined,
    resource: undefined,
    resources: undefined,
    loading: false
}

const PermissionContext = createContext(initialState);
export default PermissionContext;