import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';


const RedirectRoute = ({ ...rest }) => {
    const [isAuth, setState] = useState<boolean | undefined>(undefined);

    useEffect(() => {
        loginChallenge()
    }, []);

    const loginChallenge = async () => {
        const challenge: boolean = await fetch("/api/user", {
            method: 'GET',
            mode: 'same-origin',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
        }).then(response => {
            if (response.ok) {
                return true
            }
            return false;
        })
        return challenge ? setState(true) : setState(false);
    }

    return (
        <>
            {isAuth !== undefined ?
                <Route {...rest} render={
                    props => {
                        if (isAuth) {
                            return <Redirect to="/dashboard" />
                        } else {
                            return <Redirect to="/authenticate" />
                        }
                    }
                } /> : null
            }
        </>
    )
}

export default RedirectRoute;
