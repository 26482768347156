import React, { useContext, useEffect, useState } from 'react'
import {Route, Switch, useHistory, useLocation} from 'react-router-dom';
import DataProcessorRequest from '../DataProcessor/DataRequest';
import useNotification from '../Notifications/NotificationHook';
import PermissionContext from '../Permissions/Context'
import { CircularProgress } from '@material-ui/core';

const MessageDecoder = () => {
    const [receivedHash, setReceivedHash] = useState<string>();
    const history = useHistory();
    const { rqResource, decodePermission } = useContext(PermissionContext)
    const [loading, setLoading] = useState(false)

    const { addNotification } = useNotification()

    const location = useLocation()
    const queryParameters = new URLSearchParams(location.search)

    const handleError = (err: any) => {
        console.error(err)
    }

    useEffect(() => {
        if (!rqResource) {
            let message = queryParameters.get("message") || ""
            decodePermission(message)
            setReceivedHash(message)
            setLoading(true)
        } else {
            setLoading(false)
            if (rqResource.type === "correlation") {
                addNotification("Successfully added DP", 200)
                history.push('/dashboard')
            } else if (rqResource.type === "permission") {
                history.push("/decode/new-request")
            } else {
                addNotification("Something went wrong", 300)
            }
        }
    }, [rqResource]);

    return (
        <>
            { loading ?
                <CircularProgress style={{ marginTop: "20px" }} />
                :
                <Switch>
                    <Route path="/decode/new-request">
                        <DataProcessorRequest id={receivedHash} />
                    </Route>
                </Switch>
            }
        </>
    )
}

export default MessageDecoder;
