import nl from "./nl.json"
import en from "./en.json"

export const dictionaryList: { [key:string]: any} = {en, nl};

export interface ILanguageOptions {
    en: string,
    nl: string
}

export const languageOptions: ILanguageOptions = {
    en: "English",
    nl: "Nederlands"
}