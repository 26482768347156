import React from "react";
import "./CustomBottom.css";
import CloseIcon from '@material-ui/icons/Close';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CheckIcon from '@material-ui/icons/Check';
import { SvgIcon } from "@material-ui/core";
import { ReactComponent as EditSVG } from "../Icons/icons_edit.svg"
import { useHistory } from "react-router-dom";

interface INAV {
  goBack?: () => void         // go to previous page
  goNext?: () => void         // go to next page
  finishString: () => string  // string for final step
  edit?: () => void           // enable edit button
  cancel?: () => void         // cancel the action currently displayed
}

export default function BottomNav(props: INAV) {

  const history = useHistory();

  return (
    <div className="BottomNav">
      <div className="BottomNavButton" onClick={props.cancel}>
        <div className="BottomNavIcon BottomNavIcon-Side"><CloseIcon style={{ fill: "red" }} /></div>
        <p className="BottomNavText">{history.location.pathname.endsWith("information") ? "Remove Acess" : "Decline Access"}</p>
      </div>
      <div className="BottomNavButton" onClick={props.edit}>
        <div className="BottomNavIcon BottomNavIcon-Middle">
          <SvgIcon style={{ fill: "black" }}>
            <EditSVG />
          </SvgIcon></div>
        <p className="BottomNavText">Edit</p>
      </div>
      <div className="BottomNavButton" onClick={props.goNext}>
        <div className="BottomNavIcon BottomNavIcon-Side">
          {props.finishString() === "Finish" || props.finishString() === "Allow Access" ?
            <CheckIcon style={{ fill: "green" }} />
            : <ArrowForwardIcon style={{ fill: "green" }} />}
        </div>
        <p className="BottomNavText">{props.finishString()}</p>
      </div>
    </div>
  );
}