import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import LanguageContext from '../../languages/Context';
import DataU from "../../Icons/dataU_grey-powered.svg";

const useStyles = makeStyles(() => ({
    button: {
        width: "300px",
        background: "#007090",
        color: "white",
        fontWeight: "bold"
    },
}));

// Renders login screen
function Login() {
    const classes = useStyles();
    const languageContext = useContext(LanguageContext);

    const handleRedirect = () => {
        window.location.href='/login'; // will be proxied to keycloack server
    }

    return (
        <>
            <div className="LoginContainer">
                <div className="Title">
                    <img src={DataU} alt="DataU Logo" width="300" height="200" />
                </div>
                <div className="Authenticate">
                    <Button
                        onClick={handleRedirect}
                        className={classes.button}
                        variant="contained">{languageContext.dictionary["authentication"]}
                    </Button>
                </div>
            </div>
        </>
    );
}

export default Login;
