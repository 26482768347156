import { Button } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react'
import QrReader from 'react-qr-reader'
import { Route, Switch, useHistory } from 'react-router-dom';
import DataProcessorRequest from '../DataProcessor/DataRequest';
import useNotification from '../Notifications/NotificationHook';
import PermissionContext from '../Permissions/Context'
import { CircularProgress } from '@material-ui/core';

const QRScanner = () => {
    const [qr, setQR] = useState();
    const [succesfull, setSucces] = useState(false)
    const [scannedHash, setHash] = useState<string>();
    const history = useHistory();
    const { rqResource, decodePermission } = useContext(PermissionContext)
    const [loaded, setLoaded] = useState(false)
    const [loading, setLoading] = useState(false)

    const { addNotification } = useNotification()

    const handleScan = (data: any) => {
        if (data) {
            setQR(data)
            decodePermission(data)
            setHash(data)
            setSucces(true)
            setLoading(true)
        }
    }
    const handleError = (err: any) => {
        console.error(err)
    }

    useEffect(() => {
        if (succesfull && rqResource) {
            setLoaded(true)
            setLoading(false)
            if (rqResource.type === "correlation") {
                addNotification("Succesfully added DP", 200)
                history.push('/dashboard')
            } else if (rqResource.type === "permission") {
                history.push("/scanner/new-request")
            } else {
                addNotification("Something went wrong", 300)
            }
        }
    }, [succesfull, rqResource]);

    return (
        <>
            { loaded ?
                <>
                    <Switch>
                        <Route path="/scanner/new-request">
                            <DataProcessorRequest id={scannedHash} />
                        </Route>
                    </Switch>
                </>
                :
                <> {loading ? <CircularProgress style={{ marginTop: "20px" }} /> :
                    <>
                        <div className="Search" style={{justifyContent: "space-between"}}>
                            <div style={{height: "100%"}}>
                                <Button onClick={() => history.push("/dashboard")} style={{
                                    color: "white", marginTop: "10px", marginLeft: "5px", textTransform: 'none',
                                    fontWeight: 'bold'
                                }}>
                                    Close
                                </Button>
                            </div>
                            <div className="tl" style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>

                                <text style={{color: "white", margin: 0}}>Qr-Scanner</text>
                                <text style={{color: "white", margin: 0, fontSize: 12}}>Scan data processor's QR code
                                </text>
                            </div>
                            <div style={{width: "64px", height: "36px"}}></div>
                        </div>
                        <div className="Content">
                            <QrReader
                                delay={300}
                                onError={handleError}
                                onScan={handleScan}
                                style={{width: '100%'}}/>
                        </div>
                    </>
                    }
                </>
            }
        </>
    )
}

export default QRScanner;
