import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { makeStyles, Theme } from '@material-ui/core/styles';
import useNotification from '../Notifications/NotificationHook';

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

// return a notification snackbar when called by the hook
export default function Notification() {
    const classes = useStyles();
    const { error, removeNotification } = useNotification();

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        removeNotification();
    };

    // determine type of alert depending on the statuscode 
    const getAlert = (message: string, statusCode: number) => {
        if (statusCode === 401) {
            return <Alert onClose={handleClose} severity="warning">{message}</Alert>
        } else if (statusCode === 300) {
            return <Alert onClose={handleClose} severity="error" >{message}</Alert>
        } else if (statusCode === 200) {
            return <Alert onClose={handleClose} severity="success" >{message}</Alert>
        }
        return <Alert onClose={handleClose} severity="info">{message}</Alert>
    }

    return (
        <div className={classes.root}>
            {(error.message && error.status) &&
                <Snackbar open={error.message !== undefined && error.status !== undefined}
                    autoHideDuration={3000}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}>
                    {getAlert(error.message, error.status)}
                </Snackbar>
            }
        </div>
    );
}