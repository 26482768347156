import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { useCookies } from 'react-cookie';

import step1 from "./1.png";
import step2 from "./2.png";
import step3 from "./3.png";
import step4 from "./4.png";


const useStyles = makeStyles({
    root: {
        width: "100%",
        flexGrow: 1,
        position: "absolute",
        bottom: 0,
        padding: "0 0 8px 0"
    },
});

// render tutorial screen
export default function Tutorial() {
    const classes = useStyles();
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const history = useHistory();
    const [cookie, setCookie] = useCookies(['firstLogin'])

    // go to next step
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    // go back one step
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    // show text and pictures based on active step
    const showContent = () => {
        if (activeStep === 0) {
            return (
                <>
                    <div style={{ width: "200px", textAlign: "center", margin: "50px" }}>
                        <Typography variant="h6" gutterBottom={true}>
                            What is DataU?
                    </Typography>
                    </div>
                    <div style={{ width: "50%" }}>

                    </div>
                    <div style={{ width: "200px", textAlign: "center", margin: "20px" }}>
                        <Typography variant="body1" gutterBottom={true}>
                            DataU will help you manage the way you share your personal data on the internet
                    </Typography>
                    </div>
                </>
            )
        } else if (activeStep === 1) {
            return (
                <>
                    <div style={{ width: "200px", textAlign: "center", margin: "50px" }}>
                        <Typography variant="h6" gutterBottom={true}>
                            Control acess to your personal data
                    </Typography>
                    </div>
                    <div >
                        <img style={{ maxHeight: "50vh" }} alt="" src={step1}></img>
                    </div>
                </>
            )
        } else if (activeStep === 2) {
            return (
                <>
                    <div style={{ width: "200px", textAlign: "center", margin: "20px" }}>
                        <Typography variant="h6" gutterBottom={true}>
                            See who has your data & how your data is being used
                    </Typography>
                    </div>
                    <div >
                        <img style={{ maxHeight: "50vh" }} alt="" src={step2}></img>
                    </div>
                </>
            )
        } else if (activeStep === 3) {
            return (
                <>
                    <div style={{ width: "200px", textAlign: "center", margin: "50px" }}>
                        <Typography variant="h6" gutterBottom={true}>
                            Modify your personal data directly
                    </Typography>
                    </div>
                    <div >
                        <img style={{ maxHeight: "50vh" }} alt="" src={step3}></img>
                    </div>
                </>
            )
        }
        else if (activeStep === 4) {
            return (
                <>
                    <div style={{ width: "200px", textAlign: "center", margin: "50px" }}>
                        <Typography variant="h6" gutterBottom={true}>
                            Manage your acess permissions at any time
                    </Typography>
                    </div>
                    <div >
                        <img style={{ maxHeight: "50vh" }} alt="" src={step4}></img>
                    </div>
                </>
            )
        }
    }

    const handleRedirect = () => {
        setCookie('firstLogin', false, { path: '/', maxAge: 10000000 })
        history.push("/dashboard")
    }

    return (
        <>
            <div className="TutorialContainer">
                <Button onClick={handleRedirect} style={{ padding: "6px 16px", alignSelf: "flex-end" }}>
                    {activeStep < 4 ? <span>Skip</span> : <span>Finish</span>}
                </Button>
                {showContent()}
                <MobileStepper
                    variant="dots"
                    steps={5}
                    position="static"
                    activeStep={activeStep}
                    className={classes.root}
                    nextButton={
                        <Button size="small" onClick={handleNext} disabled={activeStep === 4}>
                            Next
                            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                        </Button>
                    }
                    backButton={
                        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                            Back
                        </Button>
                    }
                />
            </div>
        </>
    );
}
