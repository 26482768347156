import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        width: "80vw",
        alignSelf: "center",
        overflow: "auto",
        height: "85%"
    },
    title: {
        fontSize: 14,
        fontWeight: "bold"
    },
    pos: {
        marginBottom: 12,
    },
    button: {
        margin: 10,
        marginTop: 0
    },
});

export default function TermsCard({url}: any) {
    const classes = useStyles();

    return (
        <>
        <Card className={classes.root}>
            <CardContent style={{paddingBottom:0}}>
                <Typography className={classes.title} color="textPrimary" gutterBottom>
                    Introduction
                </Typography>
                <Typography variant="body2" component="p" className={classes.pos}>
                    Introduction
                    These Website Standard Terms and Conditions written on this webpage shall manage your use of our website, Webiste Name accessible at Website.com.
                </Typography>
                <Typography variant="body2" component="p" className={classes.pos}>
                    These Terms will be applied fully and affect to your use of this Website. By using this Website, you agreed to accept all terms and conditions written in here.
                    You must not use this Website if you disagree with any of these Website Standard Terms and Conditions.
                </Typography>
                <Typography variant="body2" component="p" className={classes.pos}>
                    Minors or people below 18 years old are not allowed to use this Website.
                </Typography>
                <Typography variant="body2" component="p" className={classes.pos}>
                    Intellectual Property Rights
                    Other than the content you own, under these Terms, Company Name and/or its licensors own all the intellectual property rights and materials contained in this Website.
                </Typography>
                <Typography variant="body2" component="p" className={classes.pos}>
                    You are granted limited license only for purposes of viewing the material contained on this Website.
                    Restrictions
                </Typography>
            </CardContent>
            <Button variant="contained" color="primary" className={classes.button} onClick={() => window.open(url)} size="small">Read More</Button>
        </Card>
        </>
    );
}