import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import { DialogTexts } from "./DialogTextTypes";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface DialogInput {
    open: boolean,
    closeDialog: () => void,
    handleConfirm: () => void,
    dialogType: string
}

const ConfirmDialog = ({ open, closeDialog, handleConfirm, dialogType }: DialogInput) => {

    let cancelText: string = "";
    let acceptText: string = "";
    let mainText: string = "";

    if (dialogType === "remove") {
        cancelText = DialogTexts.removeAccessDialog.cancelButton;
        acceptText = DialogTexts.removeAccessDialog.removeButton;
        mainText = DialogTexts.removeAccessDialog.mainText;
    } else if (dialogType === "accept") {
        cancelText = DialogTexts.confirmAccessDialog.cancelButton;
        acceptText = DialogTexts.confirmAccessDialog.acceptButton;
        mainText = DialogTexts.confirmAccessDialog.mainText;
    } else {
        cancelText = DialogTexts.saveDialog.noButton;
        cancelText = DialogTexts.saveDialog.yesButton;
        cancelText = DialogTexts.saveDialog.mainText;
    }

    const handleClickAccept = () => {
        handleConfirm();
    };

    const handleClickCancel = () => {
        closeDialog();
    };

    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClickCancel}
                aria-describedby="alert-dialog-slide-description"
                style={{ borderRadius: "10px" }}
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description" style={{ textAlign: "center", color: "#474747", marginTop: "20px", marginBottom: "20px" }}>
                        {mainText}
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ justifyContent: "space-evenly", paddingBottom: "25px" }}>
                    <Button onClick={handleClickCancel} style={{ textTransform: "none", color: "#474747", fontWeight: "bold", width: "40%" }} >
                        {cancelText}
                    </Button>
                    <Button onClick={handleClickAccept} style={{ width: "40%", background: "#EF807D", textTransform: "none", fontWeight: "bold", color: "white" }}>
                        {acceptText}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
export default ConfirmDialog;

