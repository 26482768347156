import React, { useState } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import eng from "../components/Settings/eng.png"
import nl from "../components/Settings/nl.png"
import { useEffect } from 'react';
import { useContext } from 'react';
import LanguageContext from './Context';
import { languageOptions } from './';
import { useHistory } from 'react-router-dom';
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Divider } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '85%',
            maxWidth: '36ch',
            backgroundColor: theme.palette.background.paper,
            marginTop: "20px",
            marginBottom: "20px",
            padding: 0
        },
        inline: {
            display: 'inline',
        },
    }),
);

export default function LanguageList() {
    const classes = useStyles();
    const languageContext = useContext(LanguageContext)
    const { userLanguage, userLanguageChange } = useContext(LanguageContext);
    const [selectedValue, setSelectedValue] = useState(userLanguage);
    const history = useHistory();

    // set selected language by calling context method
    const handleRadioCheck = (event: any) => {
        setSelectedValue(event.target.value);
        userLanguageChange(event.target.value)
    }

    useEffect(() => {
        let defaultLanguage: any = window.localStorage.getItem('lang');
        if (!defaultLanguage) {
            defaultLanguage = window.navigator.language.substring(0, 2);
        }
        setSelectedValue(defaultLanguage)
        userLanguageChange(defaultLanguage);
    }, [userLanguageChange]);

    return (
        <div className="LanguageContainer">
            <div className="FlatHeader" style={{ justifyContent: "space-between" }}>
                <Button onClick={history.goBack} style={{ color: "white" }} startIcon={<ArrowBackIcon />}>
                    Back
                </Button>
                <div className="tl" style={{ color: "white" }}>
                    {languageContext.dictionary["languages"]}
                </div>
                <div style={{ width: "95px", height: "36px" }}></div>
            </div>
            <div className="Content">
                <List className={classes.root}>
                    {Object.entries(languageOptions).map(([id, name], index) => (
                        <>
                            <ListItem>
                                <Radio checked={selectedValue === id}
                                    onChange={handleRadioCheck}
                                    value={id}
                                    name="radio-button"
                                    inputProps={{ 'aria-label': name }} />
                                {id === 'en' ? <ListItemAvatar style={{ marginTop: "0px" }}>
                                    <Avatar style={{width: "45px", height: "30px"}} alt="English" src={eng} variant="square"/>
                                </ListItemAvatar> : null}
                                {id === 'nl' ? <ListItemAvatar style={{ marginTop: "0px" }}>
                                    <Avatar style={{width: "45px", height: "30px"}} alt="Nederlands" variant="square" src={nl} />
                                </ListItemAvatar> : null}
                                <ListItemText primary={name} />
                            </ListItem>
                            {index < Object.entries(languageOptions).length -1 && <Divider component="li" style={{ height: "10px", backgroundColor: "#eef0f1" }} />}
                        </>
                    ))}
                </List>
            </div>
        </div>
    );
}