import React, { useEffect, useContext } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import eng from "./eng.png"
import nl from "./nl.png"
import LanguageContext from '../../languages/Context';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      // maxWidth: '36ch',
      backgroundColor: theme.palette.background.paper,
      marginTop: "20px",
      marginBottom: "20px",
      color: "#474747",
      padding: 0
    },
    inline: {
      display: 'inline',
    },
    mainContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "center"
    },
    centeringContainer: { // used for aligning the list and logout divs
      width: "80%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },
    logout: {
      color: "#007293",
      alignSelf: "flex-start",
      cursor: "pointer"
    }
  }),
);

export default function SettingsList() {

  const classes = useStyles();
  const language = useContext(LanguageContext);
  const { userLanguage, userLanguageChange } = useContext(LanguageContext);
  const history = useHistory();

  // set selected language by calling context method
  const handleLanguageChange = (e: any) => {
    history.push("/languages")
  }

  useEffect(() => {
    // try to get default language from localstorage, if there is none
    // get it from the browser 
    let defaultLanguage: any = window.localStorage.getItem('lang');
    if (!defaultLanguage) {
      defaultLanguage = window.navigator.language.substring(0, 2);
    }
    userLanguageChange(defaultLanguage);
  }, [userLanguage]);

  return (
    <>
      <div className={classes.mainContainer}>
        <div className={classes.centeringContainer}>
          <List className={classes.root}>
            <ListItem alignItems="flex-start" onClick={handleLanguageChange}>
              {userLanguage === 'en' ? <ListItemAvatar style={{ marginTop: "0px" }}>
                <Avatar style={{ width: "45px", height: "30px" }} alt="English" variant="square" src={eng} />
              </ListItemAvatar> : <ListItemAvatar style={{ marginTop: "0px" }}>
                  <Avatar style={{ width: "45px", height: "30px" }} alt="Nederlands" variant="square" src={nl} />
                </ListItemAvatar>}
              <ListItemText primary={language.dictionary["name"]} />
            </ListItem>
            <Divider component="li" style={{ height: "10px", backgroundColor: "#eef0f1" }} />
            <ListItem alignItems="flex-start" onClick={() => history.push('/terms-and-conditions')}>
              <ListItemText
                primary="Terms and conditions"
              />
            </ListItem>
            <Divider component="li" style={{ height: "10px", backgroundColor: "#eef0f1" }} />
            <ListItem alignItems="flex-start" onClick={() => history.push('/tutorial')}>
              <ListItemText
                primary="Help"
              />
            </ListItem>
          </List>
          <div onClick={() => window.location.href = '/logout'} className={classes.logout}>
            Log out
          </div>
        </div>
      </div>
    </>
  );
}