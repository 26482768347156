export const DialogTexts = {
    removeAccessDialog: {
        cancelButton: "Cancel",
        removeButton: "Remove", 
        mainText: "Are you sure you want to remove access to this data?" 
    },
    confirmAccessDialog: {
        cancelButton: "Cancel", 
        acceptButton: "Accept",
        mainText: "Are you sure you want to grant access to this data?"
    },
    saveDialog: {
        noButton: "No",
        yesButton: "Yes",
        mainText: "Are you sure you want to save these changes?"
    }
}