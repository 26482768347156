import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch, useHistory } from "react-router-dom"
import SimpleBottomNavigation from '../BottomNavigation';
import SettingsList from '../Settings/List';
import DataProcessorList from "../DataProcessor/DataProcessorList"
import { IconButton, InputAdornment, makeStyles, SvgIcon, TextField } from "@material-ui/core";
import { ReactComponent as MySVG } from "../../Icons/icons_scan.svg"
import { useCookies } from 'react-cookie';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles({
    root: {
        "& .MuiInput-underline::before": {
            borderBottomColor: "white"
        },
        "& .MuiInput-underline::after": {
            borderBottomColor: "white"
        },
        "& .MuiInputLabel-root": {
            color: "white"
        },
        "& .MuiInputBase-input": {
            color: "white"
        },
        "& .MuiIconButton-root": {
            padding: 0
        },
        "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
            borderBottomColor: "white"
        },
    },
    titleCentering: {
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        justifyItems: "center",
        color: "white"
    },
    fakeDiv: { // create hidden div for easy centering with flexbox
        width: "48px",
        height: "1px",
        visibility: "hidden",
        color: "white"
    },
    pageName: {
        marginTop: "21px",
        marginBottom: "21px"
    }
});

function Dashboard() {
    const history = useHistory();
    const classes = useStyles();

    const [cookies] = useCookies(['firstLogin'])
    const [filter, setFilter] = useState<string>();

    // show tutorial page on first login
    useEffect(() => {
        if (cookies.firstLogin !== "false") {
            history.push("/tutorial")
        }
    }, []);

    return (
        <div className="DashboardContainer">
            {history.location.pathname === "/dashboard/data" ? // display different headers depending on page
                <div className="SearchHeader">
                    <div className={classes.titleCentering} >
                        <div className={classes.fakeDiv}></div>
                        <div className={classes.pageName}>My shared data</div>
                        {<IconButton onClick={() => history.push("/scanner")} style={{ fill: "white", marginRight: "10px", marginLeft: "-10px" }}>
                            <SvgIcon style={{ fill: 'white' }}>
                                <MySVG />
                            </SvgIcon>
                        </IconButton>
                        }
                    </div>
                    {<TextField
                        onChange={(e: any) => setFilter(e.target.value)}
                        className={classes.root}
                        placeholder="Search organization"
                        variant="standard"
                        style={{ width: "80%", marginBottom: "15px", marginTop: "6px" }}
                        value={filter}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">
                                <IconButton
                                    aria-label="Search organizations"
                                    style={{ color: "white" }}
                                >
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>,
                            endAdornment: <InputAdornment position="end">
                                <IconButton
                                    aria-label="Remove filter"
                                    onClick={() => setFilter('')}
                                    style={{ color: "white" }}
                                >
                                    {filter ? <CloseIcon /> : null}
                                </IconButton>
                            </InputAdornment>
                        }}
                    />}
                </div> :
                <div className="FlatHeader" style={{ display: "flex", justifyContent: "center", alignItems: "center", color: "white" }}>
                    My settings
                </div>
            }

            <div className="Content">
                <Switch>
                    <Route path={"/dashboard/"} exact>
                        <Redirect to={"/dashboard/data"} />
                    </Route>
                    <Route path={"/dashboard/data"}>
                        <DataProcessorList filter={filter} />
                    </Route>
                    <Route path={"/dashboard/settings"}>
                        <SettingsList />
                    </Route>
                </Switch>
            </div>
            <SimpleBottomNavigation />
        </div>
    );
}
export default Dashboard;
