import { createContext } from "react";
import { dictionaryList, ILanguageOptions } from "../languages";

const LanguageContext = createContext(
    {
        userLanguage: 'en',
        dictionary: dictionaryList.en,      
        userLanguageChange: (selected: keyof ILanguageOptions) => {}
    }
);

export default LanguageContext;
