import React from "react"
import { useState } from "react";
import { dictionaryList, languageOptions, ILanguageOptions } from ".";
import LanguageContext from "./Context";

export const LanguageProvider = ({ children }: any) => {
    const [userLanguage, setUserLanguage] = useState(window.localStorage.getItem('lang') || 'en');

    const provider = {
        userLanguage,
        dictionary: dictionaryList[userLanguage],
        userLanguageChange: (selected: keyof ILanguageOptions) => {
            const newLanguage = languageOptions[selected] ? selected : 'en'
            setUserLanguage(newLanguage);
            window.localStorage.setItem('lang', newLanguage)
        }
    };

    return (
       <LanguageContext.Provider value={provider}>
           {children}
       </LanguageContext.Provider>
    );
};
