import React from 'react';
import { useHistory } from 'react-router-dom';
import TermsCard from './T&C';
import { Button } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

export default function TermsAndConditions() {
    const history = useHistory();

    return (
        <div className="DashboardContainer">
            <div className="FlatHeader" style={{ color: "white", flexDirection: "row", justifyContent: "space-between" }}>
                <Button onClick={history.goBack} style={{ padding: "6px 16px", color: "white" }} startIcon={<ArrowBackIcon />}>
                    Back
                </Button>
                Terms and Conditions
                <div style={{width: "95px", height: "36px"}}></div>
            </div>
            <div className="ListContent" style={{ marginTop: "20px" }}>
                <TermsCard />
            </div>
        </div>
    );
}