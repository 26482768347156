import React, { useContext, useEffect } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import PermissionContext from '../Permissions/Context';
import { CircularProgress } from '@material-ui/core';
import logo from '../../Icons/index.png';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '80%',
      maxWidth: '36ch',
      backgroundColor: theme.palette.background.paper,
      marginBottom: "20px",
      marginTop: "20px",
      borderRadius: "7.5px"
    },
    inline: {
      display: 'inline',
    },
  }),
);

interface Filter {
  filter: string | undefined
}

export default function DataProcessorList(props: Filter) {
  const classes = useStyles();
  const history = useHistory();
  const { resources, loading, getDataProcessors } = useContext(PermissionContext);

  useEffect(() => {
    getDataProcessors(); // get data processors on every render
  }, []);

  const handleItemClick = (item: string) => {
    history.push("/data-processor/" + item) // route to particular DP
  }

  // show list of all DP's
  const getItems = (item: any, index: number, lenght: number) => {
    return (
      <>
        <ListItem key={item[0]} alignItems="flex-start" onClick={() => handleItemClick(item[0])}>
          <ListItemAvatar>
            <Avatar alt="Twitter" src={item[1].URI} />
          </ListItemAvatar>
          <ListItemText
            primary={item[1].organizationName}
            secondary={
              <React.Fragment>
                <Typography
                  component="span"
                  variant="body2"
                  className={classes.inline}
                  color="textPrimary"
                >
                </Typography>
                {item[1].countryName}
              </React.Fragment>
            }
          />
        </ListItem>
        {index < lenght - 1 && <Divider component="li" style={{ height: "10px", backgroundColor: "#eef0f1" }} />} </>) // do not show divider on last item
  }

  let filteredData: any = {};
  if (resources) {
    filteredData = Object.entries(resources)
  }

  if (props.filter) {
    filteredData = filteredData.filter((entry: any) => entry[1].organizationName.toLowerCase().includes(props.filter!.toLowerCase()));
  }

  return (
    <>
      {loading ? <CircularProgress style={{ marginTop: "20px" }} />
        : filteredData.length > 0 ?
          <List className={classes.root} >
            {filteredData.map((resource: any, i: any) => getItems(resource, i, filteredData.length))}
          </List>
          : <div style={{ position: "absolute", top: "calc(50% - 56px)" }}>
            <img src={logo} style={{ filter: "grayscale(100%) opacity(40%)" }} />
            <div style={{ color: "#949491" }}>No data</div>
          </div>
      }
    </>
  );
}