import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { Link, useHistory } from 'react-router-dom';
import LanguageContext from '../languages/Context';
import { SvgIcon } from "@material-ui/core";
import { ReactComponent as DataSvg } from "../Icons/icons_my-data-full.svg"
import { ReactComponent as SettingsSVG } from "../Icons/icons_settings-full.svg"

const useStyles = makeStyles({
  root: {
    width: "100%",
    justifyContent: "space-evenly",
    bottom: 0,
    position: "absolute",
  },
});

export default function SimpleBottomNavigation() {
  const classes = useStyles();
  const route = useHistory();
  const [value, setValue] = useState(0);
  const languageContext = useContext(LanguageContext);

  useEffect(() => {
    if (route.location.pathname.includes("settings")) {
      setValue(1)
    }
  }, [route.location.pathname]);


  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      showLabels
      className={classes.root}
    >
      <BottomNavigationAction
        component={Link}
        to="/dashboard/data"
        label={languageContext.dictionary["data"]}
        icon={<SvgIcon><DataSvg /></SvgIcon>}
      />
      <BottomNavigationAction
        component={Link}
        to="/dashboard/settings"
        label={languageContext.dictionary["settings"]}
        icon={<SvgIcon><SettingsSVG /></SvgIcon>}
      />
    </BottomNavigation>
  );
}
