
import { ContextType, IAction, Permission, ActionType } from "./Context";

export default (state: ContextType, action: IAction<Permission>): ContextType => {
    switch (action.kind) {
        case ActionType.ERROR:
            return { ...state, errors: action.errors };
        case ActionType.SET_LOADING:
            return { ...state, loading: action.loading };
        case ActionType.SET_REQUEST_RESOURCE:
            const rqResource = action.rqResource!;
            return { ...state, rqResource: rqResource };
        case ActionType.SET_RESOURCE:
            const resource = action.resource!;
            return { ...state, resource: resource };
        case ActionType.SET_RESOURCES:
            const resources = action.resources!;
            return { ...state, resources: resources };
        default:
            return state;
    }
};
