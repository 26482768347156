import React from 'react';
import { Redirect, Route, Switch, useParams } from "react-router-dom"
// import DataProcessorRequest from "./DataRequest"
import DataProcessorInformation from "./Data"

// router for data proccessors, either show the information of one or show request data
function DataProcessorRouter() {
  const {id} = useParams<{id: string}>();

  return (
      <Switch>
        <Route path="/data-processor/:id" exact>
          <Redirect to={"/data-processor/" +id+"/information"} />
        </Route>
        <Route path="/data-processor/:id/information" component={DataProcessorInformation}/>
        {/* This route is currently handled by /scanner decide on best on in future
        <Route path="/data-processor/:id/new" component={DataProcessorRequest}/> */}
      </Switch>
  );
}

export default DataProcessorRouter;
